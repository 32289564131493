<template>
    <p class="h20"></p>
    <!-- <el-button-group> -->
    <div class="topbuts">
        <el-button @click="cgeType('')" size="small" :type="param.messageType==''? 'primary':'default'" plain>全部</el-button>
        <el-button @click="cgeType('1')" size="small" :type="param.messageType=='1'? 'primary':'default'" plain>系统消息</el-button>
        <el-button @click="cgeType('2')" size="small" :type="param.messageType=='2'? 'primary':'default'" plain>验证消息</el-button>
    </div>
    <!-- </el-button-group> -->
    <div class="bgf mart10" v-loading="loading">
        <el-table
        :data="info.voList"
        style="width: 100%">
            <el-table-column
            fixed
            prop="date"
            label="消息内容"
            class-name="padl20 padr20"
            show-overflow-tooltip
            min-width="234px">
                <template #default="scope">
                    <div class="flex padr14 flexa">
                       <el-link :href=" '#/newsinfo/'+scope.row.id+'/'+scope.row.messageType" style="display:unset" class="text1" >{{scope.row.messageTitle}}</el-link> 
                       <span v-if="scope.row.isRead==0" class="diannew"></span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            prop="createTime"
            show-overflow-tooltip
            label="接收时间"
            >
            </el-table-column>

            <el-table-column
            prop="people"
            label="消息类型"
            show-overflow-tooltip
            min-width="80px"
            >
                <template #default="scope">
                    <span>{{['','系统消息','验证消息'][scope.row.messageType] }} </span>
                </template>
            </el-table-column>

            <el-table-column
            fixed="right"
            label="操作"
            width="100">
                <template #default="scope">
                    <span @click="del(scope.row)" class="point colred font12">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab">
            <p class="font10 cola4">共 {{info.total}} 条</p>
            <el-pagination
                v-model:currentPage="param.pageNo"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="param.pageSize"
                layout=" sizes, prev , jumper , next"
                :total="info.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { accountMsgRecord, removeMsg } from "@/utils/api1.js"

export default {
    data(){
        return{
            loading:false,
            param:{
                messageType: '',
                pageNo: 1,
                pageSize: 10 ,
            },
            info:{
                voList:[],
            },
        }
    },
    mounted(){
        this.getList();
    },
    methods:{

        getList(){
            this.loading = true;
            console.log(this.param)
            accountMsgRecord(this.param).then(res=>{
                console.log(res);
                if(res){
                    this.info = res;
                }
                this.loading = false;
            })
        },

        cgeType(num){
            this.param.messageType = num;
            this.param.pageNo = 1;
            this.getList();
        },
        del(row) {
            // console.log(row);
            this.$confirm('删除消息后您将无法在消息列表中查看到该消息了', '您确定要从消息列表删除掉这个消息嘛？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                removeMsg(row.id).then(res=>{
                    console.log(res);
                    if(res){
                        this.$message.success(res.message);
                        this.getList();
                    }
                })
            }).catch(() => {});
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.param.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.param.pageNo = val;
            this.getList();
        },
    }
}
</script>
<style lang="scss" scoped>
.w300{ width: 300px;}
.topbuts{
    button{
        margin: 0;
    }
}
.diannew{
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 80%;
    margin-bottom: 1px;
    display: inline-block;
    background: #EE1637;
    margin-left: 6px;
}
</style>